import { GatsbySeo } from 'gatsby-plugin-next-seo'

const RegistrationConfirmation = () => {
  return (
    <>
      <GatsbySeo title="Registration Confirmation" />

      <section className="section">
        <div className="container is-max-desktop">
          <h1 className="title has-text-midnightBlue is-size-4-mobile">Thanks for registering!</h1>

          <p className="has-text-midnightBlue is-size-5 is-size-6-mobile">
            We'll verify your antigen test and send out your certificate as soon as possible.
          </p>
        </div>
      </section>
    </>
  )
}

export default RegistrationConfirmation
